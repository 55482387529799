import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useLocation } from "react-router-dom";

const AuthRoute = ({ element }) => {
  const { isAuthenticated } = useAuth0();
  const location = useLocation();

  // Redirect if the user is already authenticated and trying to access root
  if (isAuthenticated && !location.pathname.startsWith("/app/successpayment")) {
    return <Navigate to="/app/starter" />;
  }

  return element;
};
export default AuthRoute;
