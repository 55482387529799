// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fallback-spinner {
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
}

.loading {
  position: absolute;
  left: calc(40% - 35px);
  top: 50%;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-sizing: border-box;
  border: 3px solid transparent;
}

.blockGIF {
  display: flex; /* Enables flexbox */
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  max-width: 300px;
  max-height: 300px;
  margin: 40px auto 10px auto; /* Centers the container itself */
}`, "",{"version":3,"sources":["webpack://./src/layouts/loader/loader.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,YAAA;EACA,WAAA;AACF;;AACA;EACE,kBAAA;EACA,sBAAA;EACA,QAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EAEA,sBAAA;EACA,6BAAA;AAEF;;AACA;EACE,aAAA,EAAA,oBAAA;EACA,uBAAA,EAAA,yBAAA;EACA,mBAAA,EAAA,uBAAA;EACA,gBAAA;EACA,iBAAA;EACA,2BAAA,EAAA,iCAAA;AAEF","sourcesContent":[".fallback-spinner {\n  position: relative;\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n.loading {\n  position: absolute;\n  left: calc(40% - 35px);\n  top: 50%;\n  width: 35px;\n  height: 35px;\n  border-radius: 50%;\n  -webkit-box-sizing: border-box;\n  box-sizing: border-box;\n  border: 3px solid transparent;\n}\n\n.blockGIF {\n  display: flex; /* Enables flexbox */\n  justify-content: center; /* Centers horizontally */\n  align-items: center; /* Centers vertically */\n  max-width: 300px;\n  max-height: 300px;\n  margin: 40px auto 10px auto; /* Centers the container itself */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
