import axios from "axios";

const preferenceService = {
  async getPreferences(token) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/preference/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(response.data);
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch preferences");
    }
  },
  async createPreferences(token, preferencesData) {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/preference/create`,
        preferencesData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error("Failed to create preferences", error);
      throw new Error("Failed to create preferences");
    }
  },
  async updatePreferences(token, preferencesData) {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}/preference/update`,
        preferencesData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (error) {
      console.error(
        "Failed to update preferences",
        error.response ? error.response.data : error
      );
      throw new Error("Failed to update preferences");
    }
  },
};

export default preferenceService;
