import React from "react";
import "./loader.scss";
// import { Spinner } from "reactstrap";
import breakgif from "../../assets/images/bg/Color-scheme-[remix].gif";

const Loader = () => (
  <div className="fallback-spinner">
    <div className="loading">
      {/* <Spinner color="light-primary" /> */}
      <img className="blockGIF" src={breakgif}></img>
    </div>
  </div>
);
export default Loader;
