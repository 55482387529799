import { lazy } from "react";
import PrivateRoute from "./PrivateRoute";
import AuthRoute from "./AuthRoute.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/layout/FullLayout.js"));
// const LoginLayout = lazy(() => import("../layouts/layout/LoginLayout.js"));
const UserHeader = lazy(() => import("../layouts/headers/UserHeader.js"));
const JobHeader = lazy(() => import("../layouts/headers/JobHeader.js"));
const MatchHeader = lazy(() => import("../layouts/headers/MatchHeader.js"));
const BlackHeader = lazy(() => import("../layouts/headers/BlackHeader.js"));

/***** Pages ****/
const Starter = lazy(() => import("../views/dashboard/Starter.js"));
const JobBoard = lazy(() => import("../views/dashboard/JobBoard.js"));
const JobDetail = lazy(() => import("../views/dashboard/JobDetail.js"));
const Matches = lazy(() => import("../views/dashboard/Matches.js"));
const Applications = lazy(() => import("../views/dashboard/Applications.js"));

const Profile = lazy(() => import("../views/dashboard/Profile.js"));
const Plan = lazy(() => import("../views/dashboard/Plan.js"));
const SuccessPayment = lazy(() =>
  import("../views/dashboard/SuccessPayment.js")
);

const Landing = lazy(() => import("../views/intro/Landing.js"));
const Preferences = lazy(() => import("../views/intro/Preferences.js"));

const NotFound = lazy(() => import("../views/NotFound.js"));
const AuthCallback = lazy(() => import("./AuthCallback.js"));

/*****Routes******/
const ThemeRoutes = [
  {
    path: "/",
    element: <AuthRoute element={<Landing />} />,
  },
  {
    path: "/callback",
    element: <AuthCallback />,
  },
  {
    path: "/preferences",
    element: (
      <PrivateRoute authorize={(preferences) => !preferences.type}>
        {/* <PrivateRoute> */}
        <Preferences />
      </PrivateRoute>
    ),
  },
  {
    path: "/app",
    element: (
      <PrivateRoute authorize={(preferences) => preferences.type}>
        {/* </PrivateRoute> */}
        <FullLayout SecondHeader={UserHeader} />
      </PrivateRoute>
    ),
    children: [
      {
        path: "starter",
        element: <Starter />,
      },
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "plan",
        element: <Plan />,
      },
      {
        path: "successpayment",
        element: <SuccessPayment />,
      },
    ],
  },
  {
    path: "/jobs",
    element: (
      <PrivateRoute>
        <FullLayout SecondHeader={JobHeader} />
      </PrivateRoute>
    ),
    children: [
      {
        path: "jobboard",
        element: <JobBoard />,
      },
    ],
  },
  {
    path: "/jobs",
    element: (
      <PrivateRoute>
        <FullLayout SecondHeader={() => <BlackHeader title="Job Details" />} />
      </PrivateRoute>
    ),
    children: [{ path: "jobdetail/:id", element: <JobDetail /> }],
  },
  {
    path: "/app",
    element: (
      <PrivateRoute>
        <FullLayout SecondHeader={MatchHeader} />
      </PrivateRoute>
    ),
    children: [
      {
        path: "matches",
        element: <Matches />,
      },
    ],
  },
  {
    path: "/app",
    element: (
      <PrivateRoute>
        <FullLayout
          SecondHeader={() => <BlackHeader title="Application Tracker" />}
        />
      </PrivateRoute>
    ),
    children: [
      {
        path: "applications",
        element: <Applications />,
      },
    ],
  },
  {
    path: "*",
    element: <FullLayout />,
    children: [{ path: "*", element: <NotFound /> }],
  },
];
export default ThemeRoutes;
