import React, { createContext, useContext, useState } from "react";

const PaymentStatusContext = createContext();

export const PaymentStatusProvider = ({ children }) => {
  const [isPaymentComplete, setIsPaymentComplete] = useState(false);

  return (
    <PaymentStatusContext.Provider
      value={{ isPaymentComplete, setIsPaymentComplete }}
    >
      {children}
    </PaymentStatusContext.Provider>
  );
};

export const usePaymentStatus = () => useContext(PaymentStatusContext);
