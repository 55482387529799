import React from "react";
import { useRoutes } from "react-router-dom";
import Loader from "./layouts/loader/Loader";
import ThemeRoutes from "./routes/Router";
import { useAuth0 } from "@auth0/auth0-react";
import { SearchProvider } from "./routes/SearchProvider";

const App = () => {
  const { isLoading } = useAuth0();
  const routing = useRoutes(ThemeRoutes);
  // const navigate = useNavigate();

  // useEffect(() => {
  //   if (!isLoading && isAuthenticated) {
  //     // Only redirect to /app/starter if the current path is "/"
  //     if (window.location.pathname === "/") {
  //       navigate("/app/starter", { replace: true });
  //     }
  //   }
  // }, [isLoading, isAuthenticated, navigate]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <SearchProvider>
      <div className="dark">{routing}</div>
    </SearchProvider>
  );
  // return (
  //   <Suspense fallback={<Loader />}>
  //     {/* <Router> */}
  //     <SearchProvider>
  //       <Routes>
  //         {ThemeRoutes.map((route, index) => (
  //           <Route key={index} path={route.path} element={route.element}>
  //             {route.children &&
  //               route.children.map((childRoute, childIndex) => (
  //                 <Route
  //                   key={childIndex}
  //                   path={childRoute.path}
  //                   element={childRoute.element}
  //                 />
  //               ))}
  //           </Route>
  //         ))}
  //       </Routes>
  //     </SearchProvider>
  //     {/* </Router> */}
  //   </Suspense>
  // );
};

export default App;
