import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import userService from "./api/UserRoute";
import preferenceService from "./api/PrefereRoute";
import JobService from "./api/JobRoute";
import matchService from "./api/MatchRoute";
import interactionService from "./api/InterRoute";
import { useAuth0 } from "@auth0/auth0-react";

const SearchContext = createContext();

export const SearchProvider = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState({});
  const [preferences, setPreferences] = useState({});
  const [searchMatch, setSearchMatch] = useState({ query: "" });
  const [searchJob, setSearchJob] = useState({ query: "" });
  const [jobList, setJobList] = useState([]);
  const [userError, setUserError] = useState(null);
  const [matches, setMatches] = useState([]);
  const [todayMatches, setTodayMatches] = useState([]);
  const [savedList, setSavedList] = useState([]);
  const [applyList, setApplyList] = useState([]);
  const [applyLength, setApplyLength] = useState(0);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const token = await getAccessTokenSilently();

      const [userData, preferenceData] = await Promise.all([
        userService.getUser(token),
        preferenceService.getPreferences(token),
      ]);

      setUserInfo(userData);
      setPreferences(preferenceData);
      // console.log(userData);

      // If no error occurred, proceed to fetch the rest of the data
      if (userData && preferenceData) {
        const [jobData, todayMatches, matches, savedList, applyList] =
          await Promise.all([
            JobService.getJobList(token),
            matchService.getMatchListToday(token),
            matchService.getMatchList(token),
            interactionService.getListSave(token),
            interactionService.getListApply(token),
          ]);
        setJobList(jobData);
        setTodayMatches(todayMatches);
        setMatches(matches);
        setSavedList(savedList);
        setApplyList(applyList);
        setApplyLength(applyList.length);
        // console.log(todayMatches);
        // console.log(jobData);
      }
    } catch (error) {
      setUserError(error);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <SearchContext.Provider
      value={{
        searchMatch,
        setSearchMatch,
        searchJob,
        setSearchJob,
        userInfo,
        preferences,
        setPreferences,
        loading,
        jobList,
        todayMatches,
        matches,
        savedList,
        setSavedList,
        userError,
        fetchData,
        applyList,
        setApplyList,
        applyLength,
        setApplyLength,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
};

export const useSearch = () => useContext(SearchContext);
